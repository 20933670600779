export const documentTemplates = {
  'divorce-petition': {
    title: 'Petição de Divórcio',
    type: 'petition',
    category: 'family',
    sections: [
      { id: 'parties', label: 'Qualificação das Partes' },
      { id: 'facts', label: 'Dos Fatos' },
      { id: 'law', label: 'Do Direito' },
      { id: 'requests', label: 'Dos Pedidos' }
    ],
    baseContent: `EXCELENTÍSSIMO(A) SENHOR(A) DOUTOR(A) JUIZ(A) DE DIREITO DA VARA DE FAMÍLIA DA COMARCA DE [COMARCA]

[QUALIFICAÇÃO DO AUTOR], vem, respeitosamente, à presença de Vossa Excelência, por seu advogado que esta subscreve, com fundamento no art. 226, § 6º, da Constituição Federal, propor a presente

AÇÃO DE DIVÓRCIO

em face de [QUALIFICAÇÃO DO RÉU], pelos fatos e fundamentos que passa a expor:`
  },
  'alimony-petition': {
    title: 'Ação de Alimentos',
    type: 'petition',
    category: 'family',
    sections: [
      { id: 'parties', label: 'Qualificação das Partes' },
      { id: 'facts', label: 'Dos Fatos' },
      { id: 'law', label: 'Do Direito' },
      { id: 'requests', label: 'Dos Pedidos' }
    ],
    baseContent: `EXCELENTÍSSIMO(A) SENHOR(A) DOUTOR(A) JUIZ(A) DE DIREITO DA VARA DE FAMÍLIA DA COMARCA DE [COMARCA]

[QUALIFICAÇÃO DO AUTOR], menor impúbere, representado por sua genitora, [NOME DA GENITORA], vem, respeitosamente, à presença de Vossa Excelência, por seu advogado que esta subscreve, com fundamento no art. 1.694 do Código Civil, propor a presente

AÇÃO DE ALIMENTOS

em face de [QUALIFICAÇÃO DO RÉU], pelos fatos e fundamentos que passa a expor:`
  }
} as const;