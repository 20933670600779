import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '@/components/ui/Button';
import { Select } from '@/components/ui/Select';
import { TextArea } from '@/components/ui/TextArea';
import { DocumentPreview } from '@/components/DocumentPreview';
import { documentTemplates } from '@/lib/document-templates';
import { generateDocument } from '@/lib/openai';
import { Loader2 } from 'lucide-react';

const schema = z.object({
  templateId: z.string().min(1, 'Selecione um modelo'),
  parties: z.string().min(10, 'Mínimo de 10 caracteres'),
  facts: z.string().min(50, 'Mínimo de 50 caracteres'),
  law: z.string().min(50, 'Mínimo de 50 caracteres'),
  requests: z.string().min(50, 'Mínimo de 50 caracteres'),
});

type FormData = z.infer<typeof schema>;

export function DocumentGenerator() {
  const [isLoading, setIsLoading] = useState(false);
  const [generatedContent, setGeneratedContent] = useState<string | null>(null);
  const [showPreview, setShowPreview] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const selectedTemplateId = watch('templateId');
  const selectedTemplate = selectedTemplateId ? documentTemplates[selectedTemplateId as keyof typeof documentTemplates] : null;

  const onSubmit = async (data: FormData) => {
    try {
      setIsLoading(true);
      const content = await generateDocument({
        template: selectedTemplate?.title || '',
        parties: data.parties,
        facts: data.facts,
        law: data.law,
        requests: data.requests,
      });
      setGeneratedContent(content);
      setShowPreview(true);
    } catch (error) {
      console.error('Error generating document:', error);
      // Add error handling UI here
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <header>
        <h1 className="text-3xl font-bold text-gray-900">Gerador de Documentos</h1>
        <p className="mt-2 text-gray-600">
          Crie documentos jurídicos personalizados com auxílio de IA
        </p>
      </header>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 bg-white p-6 rounded-lg shadow">
        <Select
          label="Tipo de Documento"
          error={errors.templateId?.message}
          {...register('templateId')}
        >
          <option value="">Selecione um modelo</option>
          {Object.entries(documentTemplates).map(([id, template]) => (
            <option key={id} value={id}>
              {template.title}
            </option>
          ))}
        </Select>

        {selectedTemplate && (
          <>
            {selectedTemplate.sections.map((section) => (
              <TextArea
                key={section.id}
                label={section.label}
                error={errors[section.id as keyof FormData]?.message}
                {...register(section.id as keyof FormData)}
                rows={6}
                className="font-mono"
              />
            ))}

            <div className="flex justify-end space-x-4">
              <Button
                type="button"
                variant="outline"
                onClick={() => generatedContent && setShowPreview(true)}
                disabled={!generatedContent}
              >
                Visualizar
              </Button>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Gerando...
                  </>
                ) : (
                  'Gerar Documento'
                )}
              </Button>
            </div>
          </>
        )}
      </form>

      {showPreview && generatedContent && (
        <DocumentPreview
          content={generatedContent}
          onClose={() => setShowPreview(false)}
        />
      )}
    </div>
  );
}