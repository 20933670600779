import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export interface GenerateDocumentParams {
  template: string;
  parties: string;
  facts: string;
  law: string;
  requests: string;
}

export async function generateDocument(params: GenerateDocumentParams): Promise<string> {
  const prompt = `
    Você é um advogado especialista em Direito Civil, com foco em Direito de Família e Sucessões.
    Gere uma peça jurídica profissional com base nas informações fornecidas:

    Modelo: ${params.template}
    Qualificação das Partes: ${params.parties}
    Dos Fatos: ${params.facts}
    Do Direito: ${params.law}
    Dos Pedidos: ${params.requests}

    A peça deve seguir o formato jurídico brasileiro, com linguagem formal e técnica apropriada.
    Inclua citações doutrinárias e jurisprudenciais relevantes quando apropriado.
  `;

  const completion = await openai.chat.completions.create({
    messages: [{ role: "user", content: prompt }],
    model: "gpt-4",
    temperature: 0.7,
  });

  return completion.choices[0].message.content || '';
}