export interface LegalReference {
  id: string;
  title: string;
  type: 'legislation' | 'jurisprudence' | 'doctrine';
  content: string;
  source: string;
  date: string;
  tags: string[];
}

export const legalReferences: Record<string, LegalReference> = {
  'cc-1694': {
    id: 'cc-1694',
    title: 'Artigo 1.694 do Código Civil',
    type: 'legislation',
    content: 'Podem os parentes, os cônjuges ou companheiros pedir uns aos outros os alimentos de que necessitem para viver de modo compatível com a sua condição social, inclusive para atender às necessidades de sua educação.',
    source: 'Código Civil',
    date: '2002',
    tags: ['alimentos', 'direito de família']
  },
  'cc-1571': {
    id: 'cc-1571',
    title: 'Artigo 1.571 do Código Civil',
    type: 'legislation',
    content: 'A sociedade conjugal termina: I - pela morte de um dos cônjuges; II - pela nulidade ou anulação do casamento; III - pela separação judicial; IV - pelo divórcio.',
    source: 'Código Civil',
    date: '2002',
    tags: ['divórcio', 'direito de família']
  },
  'stj-resp-1713123': {
    id: 'stj-resp-1713123',
    type: 'jurisprudence',
    title: 'REsp 1.713.123/SP',
    content: 'É possível a fixação de alimentos provisórios em favor do filho menor, mesmo que ainda não tenha sido reconhecida a paternidade, desde que haja indícios suficientes da relação de parentesco.',
    source: 'STJ',
    date: '2018',
    tags: ['alimentos', 'paternidade']
  }
};