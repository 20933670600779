import React, { useState } from 'react';
import { Search } from 'lucide-react';
import { legalReferences, type LegalReference } from '@/lib/legal-references';

export function Library() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState<LegalReference['type'] | 'all'>('all');

  const filteredReferences = Object.values(legalReferences).filter((ref) => {
    const matchesSearch = ref.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ref.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ref.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()));
    
    const matchesType = selectedType === 'all' || ref.type === selectedType;
    
    return matchesSearch && matchesType;
  });

  return (
    <div className="space-y-6">
      <header>
        <h1 className="text-3xl font-bold text-gray-900">Biblioteca Jurídica</h1>
        <p className="mt-2 text-gray-600">
          Acesse referências legais, jurisprudência e doutrina
        </p>
      </header>

      <div className="flex gap-4 items-center">
        <div className="flex-1 relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Pesquisar referências..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <select
          className="border border-gray-300 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value as LegalReference['type'] | 'all')}
        >
          <option value="all">Todos os tipos</option>
          <option value="legislation">Legislação</option>
          <option value="jurisprudence">Jurisprudência</option>
          <option value="doctrine">Doutrina</option>
        </select>
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {filteredReferences.map((reference) => (
          <div key={reference.id} className="bg-white p-6 rounded-lg shadow">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold text-gray-900">{reference.title}</h3>
              <span className="px-2 py-1 text-xs font-medium rounded-full bg-blue-100 text-blue-800">
                {reference.type}
              </span>
            </div>
            <p className="text-gray-600 mb-4 line-clamp-3">{reference.content}</p>
            <div className="flex flex-wrap gap-2">
              {reference.tags.map((tag) => (
                <span
                  key={tag}
                  className="px-2 py-1 text-xs font-medium rounded-full bg-gray-100 text-gray-600"
                >
                  {tag}
                </span>
              ))}
            </div>
            <div className="mt-4 text-sm text-gray-500">
              <p>Fonte: {reference.source}</p>
              <p>Data: {reference.date}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}