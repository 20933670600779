import React from 'react';
import { FileText, BookOpen, Scale } from 'lucide-react';
import { Button } from '@/components/ui/Button';

export function Dashboard() {
  return (
    <div className="space-y-6">
      <header>
        <h1 className="text-3xl font-bold text-gray-900">Bem-vindo ao Leis&Letras Family</h1>
        <p className="mt-2 text-gray-600">
          Sua plataforma inteligente para geração de documentos jurídicos em Direito de Família e Sucessões
        </p>
      </header>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <FileText className="h-6 w-6 text-blue-600" />
              </div>
              <div className="ml-5">
                <h3 className="text-lg font-medium text-gray-900">Documentos Recentes</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Acesse suas últimas peças jurídicas
                </p>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-5 py-3">
            <Button variant="primary" size="sm" className="w-full">
              Ver Documentos
            </Button>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Scale className="h-6 w-6 text-blue-600" />
              </div>
              <div className="ml-5">
                <h3 className="text-lg font-medium text-gray-900">Nova Petição</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Crie uma nova peça jurídica
                </p>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-5 py-3">
            <Button variant="primary" size="sm" className="w-full">
              Criar Documento
            </Button>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <BookOpen className="h-6 w-6 text-blue-600" />
              </div>
              <div className="ml-5">
                <h3 className="text-lg font-medium text-gray-900">Biblioteca Jurídica</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Acesse modelos e referências
                </p>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-5 py-3">
            <Button variant="primary" size="sm" className="w-full">
              Acessar Biblioteca
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}